
























import { Component, Vue } from 'vue-property-decorator';

@Component({
	name : 'wb-data-policy',
	components : {

	},
})
export default class WbDataPolicy extends Vue {
	public dataInfo = [
		{
			id : 1,
			head : 'What information is being collected',
			article : '<b>Information is being collected from our web system is following:</b> ' +
				'<br><br> ' +
				'* Name ' +
				'<br><br> ' +
				'* E-mail ' +
				'<br><br> ' +
				'* Country ' +
				'<br><br> ' +
				'* IP address ' +
				'<br><br> ' +
				'* Billing details (Country, city, address, zip, company, VAT number) ' +
				'<br><br> ' +
				'<b>Third party systems also collect the following information (Google Analytics):</b>' +
				'<br><br> ' +
				'* Time zone ' +
				'<br><br> ' +
				'* Location (via IP address geo-coding) ' +
				'<br><br> ' +
				'* Browser version ' +
				'<br><br> ' +
				'* Operating system ' +
				'<br><br> ' +
				'<b>Our Sensor Designer Application collects non personally identifying information about the client device for diagnostic purposes, such as:</b> ' +
				'<br><br> ' +
				'* IP address ' +
				'<br><br> ' +
				'* Operating system ' +
				'<br><br> ' +
				'* Resolution ' +
				'<br><br> ' +
				'* Which content is currently being played' +
				'<br><br>',
		},
		{
			id : 2,
			head : 'Who is collecting it?',
			article : '<b>Your information is being collected by the following systems:</b> ' +
				'<br><br> ' +
				'* Our web server ' +
				'<br><br> ' +
				'* Sensor Designer Application ' +
				'<br><br> ' +
				'* Google Analytics ' +
				'<br><br>',
		},
		{
			id : 3,
			head : 'Why is it collected?',
			article : 'We only collect the minimal amount of personal information to keep our systems running. ' +
				'Free users only need to supply their name and e-mail address to use our service, however, for paid users, ' +
				'we also require billing information. We collect, store and compare both user and client IP addresses ' +
				'to prevent account fraud in our systems.' +
				'<br><br>',
		},
		{
			id : 4,
			head : 'How is it secured?',
			article : 'All connections to our servers are HTTPS, which means that the traffic is encrypted, which includes web-socket connections.' +
				'<br><br>',
		},
		{
			id : 5,
			head : 'Where is it stored?',
			article : 'Our servers are hosted by Hetzner Online GmbH and stored in their Amsterdam data center.' +
				'<br><br>',
		},
		{
			id : 6,
			head : 'Children’s privacy',
			article : 'sensor.wallboard.info does not knowingly collect information from children under the age of 13. ' +
				'If you learn that a child has provided us with personal information in violation of this Privacy Policy, ' +
				'you can alert us at info@wallboard.info.' +
				'<br><br>',
		},
		{
			id : 7,
			head : 'Changes to this privacy policy',
			article : 'This Privacy Policy may be modified from time to time, so please review it frequently. ' +
				'Changes to this Privacy Policy will be posted on our websites. If we materially change how ' +
				'we use or share personal information previously collected from you through our Services, ' +
				'we will notify you through our Services, by email, or other communication.' +
				'<br><br>',
		},
		{
			id : 8,
			head : 'International data transfers',
			article : 'We may transfer personal information to countries other than the country in which the data was originally collected. ' +
				'These countries may not have the same data protection laws as the country in which you initially provided the information. ' +
				'When we transfer your personal information to other countries, we will protect that information as described in this Privacy Policy.' +
				'<br><br>',
		},
		{
			id : 9,
			head : 'Cookies',
			article : 'The Cookie policy can be found <a href="/info/cookie-policy" class="wb-text-primary">here</a>.' +
				'<br><br>',
		},
		{
			id : 10,
			head : 'How to request your data',
			article : 'If you wish to request the data we have on you, please write to us at info@wallboard.info from the same e-mail address you wish the report for.' +
				'<br><br>' +
				'<b>How to delete your data</b>' +
				'<br><br>' +
				'If you wish that all the data we have on you be deleted, please write to us at info@wallboard.info from the same e-mail address ' +
				'you wish the data to be removed for.' +
				'<br><br>',
		},
		{
			id : 11,
			head : 'Contact us',
			article : 'Feel free to contact us at info@wallboard.info with any questions!' +
				'<br><br>',
		}
	]

	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.info('%c CREATE WbDataPolicy ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbDataPolicy ', 'background: purple; color: #FFF');
	}

	public goToLogin(): void {
		this.$router.push({ name : 'wbLogin', });
	}
}
