






import { Component, Vue } from 'vue-property-decorator';

@Component({
	name : 'wb-info',
	components : {

	},
})
export default class WbInfo extends Vue {
	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.info('%c CREATE WbInfo ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbInfo ', 'background: purple; color: #FFF');
	}
}
