
























import { Component, Vue } from 'vue-property-decorator';

@Component({
	name : 'wb-cookie-policy',
	components : {

	},
})
export default class WbCookiePolicy extends Vue {
	public cookieInfo = [
		{
			id : 1,
			head : 'What Are Cookies',
			article : 'As is common practice with almost all professional websites this site uses cookies, ' +
				'which are tiny files that are downloaded to your computer, to improve your experience. ' +
				'This page describes what information they gather, how we use it, and why we sometimes ' +
				'need to store these cookies. We will also share how you can prevent these cookies from ' +
				'being stored however this may downgrade or ‘break’ certain elements of the sites functionality. ' +
				'<br><br> ' +
				'For more general information on cookies see the Wikipedia article on HTTP Cookies. ' +
				'<br><br>',
		},
		{
			id : 2,
			head : 'How We Use Cookies',
			article : 'We use cookies for a variety of reasons detailed below. Unfortunately, in most cases, ' +
			'there are no industry standard options for disabling cookies without completely disabling ' +
			'the functionality and features they add to this site. It is recommended that you leave on ' +
			'all cookies if you are not sure whether you need them or not in case they are used to ' +
			'provide a service that you use.' +
			'<br><br>',
		},
		{
			id : 3,
			head : 'Disabling Cookies',
			article : 'You can prevent the setting of cookies by adjusting the settings on your browser ' +
				'(see your browser Help for how to do this). Be aware that disabling cookies will affect ' +
				'the functionality of this and many other websites that you visit. Disabling cookies will ' +
				'usually result in also disabling certain functionality and features of this site. ' +
				'Therefore it is recommended that you do not disable cookies.' +
				'<br><br>',
		},
		{
			id : 4,
			head : 'The Cookies We Set',
			article : '* We use cookies when you are logged in so that we can remember this fact. This prevents ' +
				'you from having to log in every single time you visit a new page. These cookies are removed ' +
				'when you log out to ensure that you can only access restricted features and areas when ' +
				'logged in.' +
				'<br><br>',
		},
		{
			id : 5,
			head : 'Third Party Cookies',
			article : 'In some special cases, we also use cookies provided by trusted third parties. ' +
				'The following section details which third party cookies you might encounter through this site. ' +
				'<br><br>' +
				'* This site uses Google Analytics which is one of the most widespread and trusted ' +
				'analytics solutions on the web for helping us to understand how you use the site and ways ' +
				'that we can improve your experience. These cookies may track things such as how long you ' +
				'spend on the site and the pages that you visit so we can continue to produce engaging content. ' +
				'For more information on Google Analytics cookies, see the official Google Analytics page.' +
				'<br><br>',
		},
		{
			id : 6,
			head : 'More Information',
			article : 'Hopefully, that has clarified things for you, and as was previously mentioned if there ' +
				'is something that you aren’t sure whether you need or not it’s usually safer to leave ' +
				'cookies enabled in case it does interact with one of the features you use on our site. ' +
				'<br><br> ' +
				'However, if you are still looking for more information then you can contact us at ' +
				'info@wallboard.info.' +
				'<br><br>',
		}
	];

	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.info('%c CREATE WbCookiePolicy ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbCookiePolicy ', 'background: purple; color: #FFF');
	}

	public goToLogin(): void {
		this.$router.push({ name : 'wbLogin', });
	}
}
