import { render, staticRenderFns } from "./wb-cookie-policy.vue?vue&type=template&id=79f2c046&scoped=true&"
import script from "./wb-cookie-policy.vue?vue&type=script&lang=ts&"
export * from "./wb-cookie-policy.vue?vue&type=script&lang=ts&"
import style0 from "./wb-cookie-policy.vue?vue&type=style&index=0&id=79f2c046&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79f2c046",
  null
  
)

export default component.exports