
























import { Component, Vue } from 'vue-property-decorator';

@Component({
	name : 'wb-terms-and-conditions',
	components : {

	},
})
export default class WbTermsAndConditions extends Vue {
	public termsAndConditionsInfo = [
		{
			id : 0,
			head : '',
			article : '<span class="wb-font-large-2">Welcome to Sensors.wallboard.info!</span>' +
				'<br><br><br>',
		},
		{
			id : 1,
			head : 'Wallboard Privacy Policy',
			article : 'Sensor Wallboard Internet website is located at the URL sensor.wallboard.info ("Site"). ' +
				'This Privacy Policy details certain policies implemented throughout sensor.wallboard.info governing ' +
				'sensor.wallboard.info’s use of personally identifiable information about ' +
				'users of our Site and users of our services and/or software that is available for download on this Site. ' +
				'<br><br>',
		},
		{
			id : 2,
			head : 'Privacy Policy Updates',
			article : 'Due to the Internet’s rapidly evolving nature, sensor.wallboard.info may need to update this Privacy Policy ' +
				'from time to time. If so, sensor.wallboard.info will post our updated Privacy Policy on our Site so you are always ' +
				'aware of what personally identifiable information we may collect and how we may use this information. ' +
				'sensor.wallboard.info will also send registered users of the Site e-mail notifications notifying such ' +
				'users of any changes to the Privacy Policy. sensor.wallboard.info encourages you to review ' +
				'this Privacy Policy regularly for any changes. Your continued use of this Site and/or continued provision of personally ' +
				'identifiable information to us will be subject to the terms of the then-current Privacy Policy.' +
				'<br><br>',
		},
		{
			id : 3,
			head : 'Information Collection and Use',
			article : 'You can generally visit our Site without revealing any personally identifiable information about yourself. ' +
				'However, in certain sections of this Site and when you download any software we may offer, we may invite you to contact us ' +
				'with questions or comments or request information, or complete a profile or registration form. ' +
				'Due to the nature of some of these activities, we may collect personally identifiable information ' +
				'such as your name, e-mail address, password, organization, and other contact information that you voluntarily transmit ' +
				'with your communication to us. We also may collect information about Web visitors through web analytic software through ' +
				'your use of our Web Site.' +
				'<br><br>' +
				'When you register for an account, we will collect your first and last name, e-mail address, ' +
				'password, and organization name and use this information to create a user account where you can ' +
				'create, manage, access, and share Contents and other information that you post to the Site with other users of the Site ' +
				'that you designate.' +
				'<br><br>' +
				'We may use your personally identifiable information to register you to use our services or download client software, ' +
				'contact you to deliver certain services or information you have requested, verify your authority to enter our Site, ' +
				'improve the content and general administration of the Site and our services and provide you with notices regarding our services.' +
				'<br><br>',
		},
		{
			id : 4,
			head : 'Collected data',
			article : 'We may collect and process the following information: (a) information that you provide to us from time to time, ' +
				'including information provided at the time of registering to use sensor.wallboard.info, content, or any requests for further information ' +
				'or services. We may also ask you for information when you report a problem with sensor.wallboard.info (b) ' +
				'if you or any user contacts us, we may keep a record of that correspondence (c) details of users’ visit to sensor.wallboard.info ' +
				'and use of our service, including searches requested, page response times, lengths of visit. (d) ' +
				'details of users’ use of our service including, but not limited to, login information, browser type, and version, ' +
				'traffic data and other communication data, and the resources that users access, ' +
				'so we may compile statistics relating to the use of our service.' +
				'<br><br>',
		},
		{
			id : 5,
			head : 'Shared Distribution List',
			article : 'Through your user account, you can create a shared distribution list and designate the e-mail addresses of other users ' +
				'with whom you wish to provide access to your Contents and other information that you post on the Site. Additionally, ' +
				'if you are a Client, your Contents will be available for any third party to view at a publicly available URL unless you ' +
				'elect to become a Subscriber and thereafter choose not to make your Contents public. If you are a Subscriber ' +
				'and have elected to make your Contents public you acknowledge that third parties may view and have access to your Contents ' +
				'and that Wallboard.info has no control over third parties’ use of your Contents. ' +
				'Published Contents may be indexed by search engines. Except as set outlined in the preceding sentences, ' +
				'the Contents and other information that you post on the Site will not be shared with third parties except for those individuals ' +
				'that you designate as part of your shared distribution list.' +
				'<br><br>',
		},
		{
			id : 6,
			head : 'Children’s Privacy',
			article : 'sensor.wallboard.info recognizes the privacy interests of children and we encourage parents and guardians ' +
				'to take an active role in their children’s online activities and interests. This Site is not intended for children under ' +
				'the age of 13. Wallboard.info does not target its services or this Site to children under 13. Wallboard.info does not ' +
				'knowingly collect personally identifiable information from children under the age of 13.' +
				'<br><br>',
		},
		{
			id : 7,
			head : 'Cookies',
			article : 'We may use small files called cookies to improve the overall Site experience. ' +
				'A cookie is a piece of data stored on the user’s hard drive containing information about the user. ' +
				'Cookies generally do not permit us to personally identify you.' +
				'<br><br>' +
				'Aggregate Information.' +
				'<br><br>' +
				'The Site may track information that will be maintained, used, and disclosed in aggregate form only and which ' +
				'will not contain your personally identifiable information, for example, without limitation, ' +
				'the total number of visitors to our Site, the number of visitors to each page of our Site, browser type, ' +
				'External Web Sites (defined below) linked to and IP addresses. We may analyze this data for trends and statistics ' +
				'in the aggregate, and we may use such aggregate information to administer the Site, track users’ movement, ' +
				'and gather broad demographic information for aggregate use.' +
				'<br><br>',
		},

		{
			id : 8,
			head : 'Disclosure',
			article : 'We may provide your personally identifiable information and the data generated by cookies ' +
				'and the aggregate information to the vendors and service agencies that we may engage to assist us in providing ' +
				'our services to you. For example, we may provide your personally identifiable information to a credit card processing ' +
				'company to process your payment. We will also disclose your personally identifiable information (a) ' +
				'only if we are required to do so by law, regulation, or other government authority or otherwise ' +
				'in cooperation with an ongoing investigation of governmental authority, (b) to enforce ' +
				'the sensor.wallboard.info Terms of Use agreement or to protect our rights or (c) to protect ' +
				'the safety of users of our Site and our services. We will not sell your personally identifiable information ' +
				'to any company or organization except we may transfer your personally identifiable information ' +
				'to a successor entity upon a merger, consolidation, or other corporate reorganization in which ' +
				'sennsor.wallboard.info participates or to a purchaser or acquirer of all or ' +
				'substantially all of sensor.wallboard.info’s assets to which this Site relates.' +
				'<br><br>',
		},
		{
			id : 9,
			head : 'Links to Third Party Sites',
			article : 'The Site may provide links to other Web sites or resources over which sensor.wallboard.info does not have control ' +
				'(“External Web Sites”). Such links do not constitute an endorsement by sensor.wallboard.info of those External Web Sites. ' +
				'You acknowledge that sensor.wallboard.info is providing these links to you only as a convenience, and further agree that ' +
				'sensor.wallboard.info is not responsible for the content of such External Web Sites. ' +
				'Your use of External Web Sites is subject to the terms of use and privacy policies located on ' +
				'the linked to External Web Sites.' +
				'<br><br>',
		},
		{
			id : 10,
			head : 'Security',
			article : 'We may employ procedural and technological measures, consistent with industry practice. ' +
				'Such measures are reasonably designed to help protect your personally identifiable information from loss, ' +
				'unauthorized access, disclosure, alteration, or destruction. sensor.wallboard.info may use encryption, secure socket ' +
				'layer, and other security measures to help prevent unauthorized access to your personally identifiable information.' +
				'<br><br>',
		},
		{
			id : 11,
			head : 'Publicly share customer list',
			article : 'We sometimes display customer names and/or logos on our website to help promote sensor.wallboard.info Inc ' +
				'by sharing company names/logos of customers who have purchased from us in the past. If your company name appears on ' +
				' our website and you would like for it to be removed, please contact us and we will remove it. ' +
				'You may be asked to provide proof of relationship.' +
				'<br><br>',
		},
		{
			id : 12,
			head : 'Updating Personally Identifiable Information',
			article : 'sensor.wallboard.info provides you with the ability to review and update the registration information that you provide ' +
				'to us by accessing and modifying this information on your user profile page.' +
				'<br><br>' +
				'If you have any questions regarding this Privacy Policy please contact us via email.' +
				'<br><br>' +
				'Our computer systems are currently based in the European Union, so your data will be processed by us in the EU.' +
				'<br><br>',
		}
	];

	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.info('%c CREATE WbTermsAndConditions ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbTermsAndConditions ', 'background: purple; color: #FFF');
	}

	public goToLogin(): void {
		this.$router.push({ name : 'wbLogin', });
	}
}
